import React, { useState, useRef, useCallback } from 'react';
import { read, utils, writeFileXLSX } from 'xlsx';
import moment from 'moment';
import 'moment/locale/uk';

const Create10030Report = () => {
  const [users10030, setUsers10030] = useState(null);

  const fileInputRef = useRef(null);
  moment.locale('uk');
  const dateFormat = 'DD.MM.YYYY';

  const handleLoadPlaceButtonClick = () => {
    fileInputRef.current.click();
  };

  const getDayActivity = (value, color) => {
    if (value === null && color === '808000') return '100';
    else if (value === null && color === '969696') return '30';
    else if (value === 'ППД' && color === '993300') return 'ППД';
    else if (value === 'ВП' && color === '993300') return 'Відпустка';
    else if (value === 'КУ' && color === '993300') return 'Курси';
    else if (value === 'ШП' && color === '993300') return 'Шпиталь';
    else if (value === 'ВД' && color === '993300') return 'Відрядження';
    else if (value === 'СЗЧ' && color === '003300') return 'СЗЧ';
    else if (value === 'ЗВ' && color === '003300') return 'Звільнений';
    else if (value === 'ПР' && color === '003300') return 'Переведений';
  };

  const notesArray = ['ППД', 'Відпустка', 'Курси', 'Шпиталь', 'Відрядження', 'СЗЧ', 'Звільнений', 'Переведений'];

  const deleteLastN = (str) => {
    if (str) return str.slice(0, -1);
    return str;
  };

  const createSingleRowUsersArray = (usersArray) => {
    const newLinesUsersArray = [];

    usersArray.forEach((item) => {
      const from100 = item['100from']?.split('\n') || [];
      const to100 = item['100to']?.split('\n') || [];
      const from30 = item['30from']?.split('\n') || [];
      const to30 = item['30to']?.split('\n') || [];
      const healingFrom = item['healingFrom']?.split('\n') || [];
      const healingTo = item['healingTo']?.split('\n') || [];
      const combatOrder = item['combatOrder']?.split('\n') || [];
      const notes = item['note']?.split('\n') || [];
      const maxArrayLength = Math.max(from100.length, to100.length, from30.length, to30.length, notes.length);
      for (let i = 0; i < maxArrayLength; i++) {
        const singleLine = {
          user: i === 0 ? item.user : '',
          '100from': from100?.[i] || '',
          '100to': to100?.[i] || '',
          '30from': from30?.[i] || '',
          '30to': to30?.[i] || '',
          healingFrom: healingFrom?.[i] || '',
          healingTo: healingTo?.[i] || '',
          combatOrder: combatOrder?.[i] || '',
          notes: notes?.[i] || ''
        };
        newLinesUsersArray.push(singleLine);
      }
    });
    return newLinesUsersArray;
  };

  const handleLoadPlaceFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      const ab = await file.arrayBuffer();
      const wb = read(ab, { cellStyles: true });
      const sheetName = wb.SheetNames[0];
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet

      // Loop to cells from column B to AG
      // Initialize an array to hold the JSON result
      const jsonResult = [];

      // Define column indices for B to AG (1-indexed)
      const startColumn = 0; // Column B
      const endColumn = 32; // Column AG

      // Read headers from the first row
      const headers = [];
      for (let c = startColumn; c <= endColumn; ++c) {
        const cellAddress = utils.encode_cell({ r: 0, c: c }); // Assuming headers are in row 1
        const cell = ws[cellAddress];
        const header = cell && cell.v !== undefined ? cell.v : null;
        headers.push(header);
      }

      // Loop through rows and columns within the specified range (B1:AGn)
      const range = utils.decode_range(ws['!ref']);
      for (let r = range.s.r; r <= range.e.r; ++r) {
        const rowData = {};
        let rowIsEmpty = true; // Assume row is empty initially
        for (let c = startColumn; c <= endColumn; ++c) {
          const cellAddress = utils.encode_cell({ r: r, c: c });
          const cell = ws[cellAddress];
          const value = cell && cell.v !== undefined ? cell.v : null;
          const style = cell && cell.s ? cell.s : null;
          const color = style && style?.bgColor?.rgb ? style.bgColor.rgb : null;
          const header = headers[c - startColumn];
          rowData[header] = { value, color };
          if (value !== null && value !== '') {
            // If any cell in the row is not empty, mark the row as not empty
            rowIsEmpty = false;
          }
        }
        // If the row is empty, break the loop
        if (rowIsEmpty) {
          break;
        }
        const nameArray = rowData['ПІБ']?.value ? rowData['ПІБ']?.value.split(' ') : [];
        if (nameArray.length === 3) jsonResult.push(rowData);
      }

      // creating obj for every user
      const users = {};
      jsonResult.forEach((item) => {
        if (item['ПІБ']?.value?.length) {
          const name = item['ПІБ'].value;
          users[name] = {
            '100from': '',
            '100to': '',
            '30from': '',
            '30to': '',
            healingFrom: '',
            healingTo: '',
            combatOrder: '',
            note: ''
          };
          const days = {};
          Object.keys(item).forEach((date) => {
            if (!isNaN(parseInt(date))) {
              days[date] = getDayActivity(item[date].value, item[date].color);
            }
          });
          const daysKeys = Object.keys(days);
          let singleNote = {
            from: '',
            to: '',
            where: ''
          };
          daysKeys.forEach((day, index) => {
            // creating 100/30
            if (
              days[day] === '100' ||
              days[day] === '30' ||
              days[daysKeys[index - 1]] === '100' ||
              days[daysKeys[index - 1]] === '30' ||
              days[daysKeys[index + 1]] === '100' ||
              days[daysKeys[index + 1]] === '30'
            ) {
              if (index === 0) {
                users[name][`${days[day]}from`] += `${moment(`${day}.${sheetName}`, dateFormat).format(dateFormat)}\n`;
              } else if (index === daysKeys.length - 1) {
                if (days[day] !== days[daysKeys[index - 1]]) {
                  users[name][`${days[day]}from`] +=
                    `${moment(`${day}.${sheetName}`, dateFormat).format(dateFormat)}\n`;
                  users[name][`${days[daysKeys[index - 1]]}to`] +=
                    `${moment(`${daysKeys[index - 1]}.${sheetName}`, dateFormat).format(dateFormat)}\n`;
                }
                users[name][`${days[day]}to`] += `${moment(`${day}.${sheetName}`, dateFormat).format(dateFormat)}\n`;
              } else {
                if (days[day] !== days[daysKeys[index - 1]]) {
                  users[name][`${days[daysKeys[index - 1]]}to`] +=
                    `${moment(`${daysKeys[index - 1]}.${sheetName}`, dateFormat).format(dateFormat)}\n`;
                  users[name][`${days[day]}from`] +=
                    `${moment(`${day}.${sheetName}`, dateFormat).format(dateFormat)}\n`;
                }
              }
            }
            if (notesArray.includes(days[day])) {
              const createNoteAndToDefault = () => {
                if (singleNote.from === singleNote.to) {
                  users[name]['note'] += `${singleNote.from} ${singleNote.where}\n`;
                } else {
                  users[name]['note'] += `${singleNote.from} - ${singleNote.to} ${singleNote.where}\n`;
                }
                singleNote = {
                  from: '',
                  to: '',
                  where: ''
                };
              };
              // creating notes
              if (index === 0) {
                singleNote.from = moment(`${day}.${sheetName}`, dateFormat).format(dateFormat);
                singleNote.where = days[day];
                if (days[daysKeys[index + 1]] !== days[day]) {
                  singleNote.to = moment(`${day}.${sheetName}`, dateFormat).format(dateFormat);
                  createNoteAndToDefault();
                }
              } else if (index === daysKeys.length - 1) {
                if (days[daysKeys[index - 1]] === days[day]) {
                  singleNote.to = moment(`${day}.${sheetName}`, dateFormat).format(dateFormat);
                  createNoteAndToDefault();
                } else {
                  singleNote.from = moment(`${day}.${sheetName}`, dateFormat).format(dateFormat);
                  singleNote.to = moment(`${day}.${sheetName}`, dateFormat).format(dateFormat);
                  singleNote.where = days[day];
                  createNoteAndToDefault();
                }
              } else {
                if (days[daysKeys[index - 1]] !== days[day]) {
                  singleNote.from = moment(`${day}.${sheetName}`, dateFormat).format(dateFormat);
                  singleNote.where = days[day];
                }
                if (days[daysKeys[index + 1]] !== days[day]) {
                  singleNote.to = moment(`${day}.${sheetName}`, dateFormat).format(dateFormat);
                  createNoteAndToDefault();
                }
              }
            }
          });
        }
      });
      const usersArray = Object.keys(users).map((user) => {
        return {
          user: user,
          '100from': deleteLastN(users[user]['100from']),
          '100to': deleteLastN(users[user]['100to']),
          '30from': deleteLastN(users[user]['30from']),
          '30to': deleteLastN(users[user]['30to']),
          healingFrom: deleteLastN(users[user]['healingFrom']),
          healingTo: deleteLastN(users[user]['healingTo']),
          combatOrder: deleteLastN(users[user]['combatOrder']),
          note: deleteLastN(users[user]['note'])
        };
      });
      setUsers10030(createSingleRowUsersArray(usersArray));
    } catch (error) {
      // Handle errors
      console.error('Error handling file change:', error);
    }
  };

  const handleDownloadFile = useCallback(() => {
    /* generate worksheet from state */
    const ws = utils.json_to_sheet(users10030);

    /* Apply cell style with wrap text */
    const range = utils.decode_range(ws['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = utils.encode_cell(cell_address);
        const cell = ws[cell_ref];
        if (!cell) continue;
        if (!cell.s) cell.s = {};
        cell.s.alignment = { wrapText: true };
      }
    }

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');

    /* export to XLSX */
    writeFileXLSX(wb, '10030file.xlsx', { bookSST: true, cellStyles: true });
  }, [users10030]);

  return (
    <>
      {!users10030 ? (
        <div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleLoadPlaceFileChange}
            accept=".xlsx"
          />
          <button className="button" onClick={handleLoadPlaceButtonClick}>
            Завантажте місце перебування
          </button>
        </div>
      ) : (
        <button className="button" onClick={handleDownloadFile}>
          Завантажити файл
        </button>
      )}
    </>
  );
};

export default Create10030Report;
