import React from 'react';
import moment from 'moment';

const Days = () => {
    const startDate = moment('09.12.2022');
    const endDate = moment('09.12.2025');
    const daysInArmy = moment().diff(startDate, 'days');
    const daysLeft = endDate.diff(moment(), 'days');

    return (
        <div className='daysContainer'>
            <div className='daysChildContainer'>In army - <span className='daysChildContainerText'>{daysInArmy}</span> days,</div>
            <div className='daysChildContainer'>To 3 years - <span className='daysChildContainerText'>{daysLeft}</span> days.</div>
        </div>
    )
}

export default Days;