import React, { useState, useRef } from 'react';
import Settings from './Settings';
import bcrypt from 'bcryptjs';

const Home = () => {
  const units = ['реабатр', 'РудБпАК', 'адмінгрупа'];
  const [loadedFile, setLoadedFile] = useState();

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    try {
      let file = event.target.files[0]; // Get the first file selected by the user
      if (file) {
        const fileNameParts = file.name.split('.');
        const fileFormat = fileNameParts[fileNameParts.length - 1].toLowerCase();
        if (fileFormat !== 'json') return;
        // Do something with the file, e.g., read its contents
        const reader = new FileReader();
        reader.onload = (e) => {
          let file = JSON.parse(e.target.result);
          const hash = file.unit;
          const unit = units.filter((item) => bcrypt.compareSync(item, hash));
          if (unit.length === 1) {
            file.unit = unit[0];
            setLoadedFile(file);
          } else return;
        };
        reader.readAsText(file); // Read file as text, you can use other methods based on your file type
      }
    } catch (error) {
      // Handle errors
      // console.error('Error handling file change:', error);
    }
  };

  if (loadedFile && loadedFile.users.length > 0) {
    return <Settings loadedFile={loadedFile} />;
  }

  return (
    <div className="loadFileContainer">
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} accept=".json" />
      <button className="button" onClick={handleButtonClick}>
        Завантажте файл
      </button>
    </div>
  );
};

export default Home;
