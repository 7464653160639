import React, { useState, useEffect } from 'react';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import FileSaver from 'file-saver';
import vacationTemplate from '../static/vacationTemplate.docx';
import returnTemaplate from '../static/returnTemplate.docx';
import healthTemaplate from '../static/healthTemplate.docx';
import acceptingPositionTemplate from '../static/acceptingPositionTemplate.docx';
import resignationTemplate from '../static/resignationTemplate.docx';

import moment from 'moment';
import PropTypes from 'prop-types';

const CreateReport = ({
  report,
  captain,
  unitInfo,
  vacationDate,
  reportDate,
  roadDays,
  checkedUsers,
  daysOfVacationChecked,
  returnDate
}) => {
  const [generateButtonIsDissabled, setGenerateButtonIsDissabled] =
    useState(true);

  useEffect(() => {
    checkedUsers.length > 0
      ? setGenerateButtonIsDissabled(false)
      : setGenerateButtonIsDissabled(true);
  }, [checkedUsers]);

  const generateDocx = async (item) => {
    try {
      // Fetch the .docx template file
      let template = null;
      let splittedName = '';
      let data = {
        reportDate: moment(reportDate).format('DD.MM.YYYY'),
        captainRank: captain.rank,
        captainPosition: captain.position,
        captainName: captain.value,
        unit: unitInfo.unit,
        number: unitInfo.number
      };
      switch (report.value) {
        case 'vacation':
          template = vacationTemplate;

          splittedName = item.label.split(' ');

          data['vacationDate'] = moment(vacationDate).format('DD.MM.YYYY');
          data['roadDays'] = roadDays.value;
          data['daysOfVacationChecked'] = daysOfVacationChecked.value;
          data['adress'] = item.adress;
          data['phoneNum'] = item.phoneNum;
          data['position'] = item.position;
          data['rank'] = item.rank;
          data['label'] = `${splittedName[1]} ${splittedName[0]}`;
          data['rankWho'] = item.rankWho;
          data['nameWho'] = item.nameWho;
          break;

        case 'return':
          template = returnTemaplate;

          data['returnedUsers'] = item.map(
            (user, index) =>
              `- ${user.rank} ${user.label}${index === item.length - 1 ? '.' : ';'}`
          );
          data['dateOfReturn'] = moment(returnDate).format('DD.MM.YYYY');
          data['unitFullName'] = unitInfo.unitFullName;

          break;
        case 'health':
          template = healthTemaplate;
          splittedName = item.label.split(' ');

          data['position'] = item.position;
          data['rank'] = item.rank;
          data['label'] = `${splittedName[1]} ${splittedName[0]}`;
          data['rankWho'] = item.rankWho;
          data['nameWho'] = item.nameWho;
          break;
        case 'acceptingPosition':
          template = acceptingPositionTemplate;
          splittedName = item.label.split(' ');

          data['rank'] = item.rank;
          data['labelFull'] = item.label;
          data['label'] = `${splittedName[1]} ${splittedName[0]}`;
          data['positionFull'] = item.positionFull;
          break;
        case 'resignationPosition':
          template = resignationTemplate;
          splittedName = item.label.split(' ');

          data['rank'] = item.rank;
          data['labelFull'] = item.label;
          data['label'] = `${splittedName[1]} ${splittedName[0]}`;
          data['positionFull'] = item.positionFull;
          break;
        default:
          console.log('Unknown report');
      }

      const response = await fetch(template);
      const templateArrayBuffer = await response.arrayBuffer();

      // Convert the ArrayBuffer to a Uint8Array
      const uint8Array = new Uint8Array(templateArrayBuffer);

      // Load the template content into a PizZip object
      const zip = new PizZip(uint8Array);

      // Initialize the docxtemplater with the PizZip object
      const doc = new Docxtemplater();
      doc.loadZip(zip);

      // Replace the placeholders in the template with actual data
      doc.setData(data);

      // Render the document (replace the placeholders with actual data)
      doc.render();

      // Get the generated .docx document as a Blob
      const generatedDocx = doc.getZip().generate({ type: 'blob' });

      // Save the generated document using FileSaver.js
      switch (report.value) {
        case 'vacation':
          FileSaver.saveAs(generatedDocx, `${item.label}.docx`);
          break;
        case 'return':
          FileSaver.saveAs(generatedDocx, `повернення.docx`);
          break;
        case 'health':
          FileSaver.saveAs(generatedDocx, `${item.label}_оздоровчі.docx`);
          break;
        case 'acceptingPosition':
          FileSaver.saveAs(generatedDocx, `${item.label}_прийом посади.docx`);
          break;
        case 'resignationPosition':
          FileSaver.saveAs(generatedDocx, `${item.label}_здача посади.docx`);
          break;
        default:
          console.log('Unknown report');
      }
      // FileSaver.saveAs(generatedDocx, `${item.label}.docx`);
    } catch (error) {
      // Handle errors
      console.error('Error generating .docx document:', error);
    }
  };

  const createReport = () => {
    switch (report.value) {
      case 'vacation':
        checkedUsers.forEach((user) => generateDocx(user));
        break;
      case 'return':
        generateDocx(checkedUsers);
        break;
      case 'health':
        checkedUsers.forEach((user) => generateDocx(user));
        break;
      case 'acceptingPosition':
        checkedUsers.forEach((user) => generateDocx(user));
        break;
      case 'resignationPosition':
        checkedUsers.forEach((user) => generateDocx(user));
        break;
      default:
        console.log('Unknown report');
    }
  };

  return (
    <div className="createReportButton">
      <button
        className="button"
        disabled={generateButtonIsDissabled}
        onClick={createReport}
      >
        Сформувати
      </button>
    </div>
  );
};

export default CreateReport;

CreateReport.propTypes = {
  loadedFile: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      namewho: PropTypes.string,
      rank: PropTypes.string,
      rankWho: PropTypes.string,
      position: PropTypes.string,
      phoneNum: PropTypes.string,
      adress: PropTypes.string
    })
  ),
  report: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  captain: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    rank: PropTypes.string,
    position: PropTypes.string
  }),
  unitInfo: PropTypes.shape({
    unit: PropTypes.string,
    unitFullName: PropTypes.string,
    number: PropTypes.string
  }),
  vacationDate: PropTypes.instanceOf(Date),
  reportDate: PropTypes.instanceOf(Date),
  roadDays: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  checkedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      namewho: PropTypes.string,
      rank: PropTypes.string,
      rankWho: PropTypes.string,
      position: PropTypes.string,
      phoneNum: PropTypes.string,
      adress: PropTypes.string
    })
  ),
  daysOfVacationChecked: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  returnDate: PropTypes.instanceOf(Date)
};

CreateReport.defaultProps = {
  loadedFile: null,
  report: null,
  captain: null,
  unitInfo: null,
  vacationDate: '',
  reportDate: '',
  roadDays: null,
  checkedUsers: null,
  daysOfVacationChecked: null,
  returnDate: ''
};
