import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CreateReport from './CreateReport';
import Create10030Report from './Create10030Report';
import Form5Report from './Form5Report';
import PropTypes from 'prop-types';

const Settings = ({ loadedFile }) => {
  const allReports = [
    {
      label: 'Відпустка',
      value: 'vacation',
      allowedUnits: ['реабатр']
    },
    {
      label: 'Повернення',
      value: 'return',
      allowedUnits: ['реабатр']
    },
    {
      label: 'Оздоровчі',
      value: 'health',
      allowedUnits: ['реабатр']
    },
    {
      label: 'Прийом посади',
      value: 'acceptingPosition',
      allowedUnits: ['реабатр']
    },
    {
      label: 'Здача посади',
      value: 'resignationPosition',
      allowedUnits: ['реабатр']
    },
    {
      label: 'Рапорт 10030',
      value: 'moneyReport',
      allowedUnits: ['реабатр', 'РудБпАК']
    },
    {
      label: 'Форма 5',
      value: 'form5',
      allowedUnits: ['адмінгрупа']
    }
  ];

  const daysOfRoad = [
    {
      label: '1',
      value: '1 добу'
    },
    {
      label: '2',
      value: '2 доби'
    }
  ];
  const daysOfVacation = [
    {
      label: '10',
      value: '10 (десять)'
    },
    {
      label: '15',
      value: "15 (п'ятнадцять)"
    }
  ];

  const [allowedReports, setAllowedReports] = useState([]);
  const [report, setReport] = useState({ label: '', value: '' });
  const [captains, setCaptains] = useState(null);
  const [captain, setCaptain] = useState(null);
  const [vacationDate, setVacationDate] = useState(new Date());
  const [reportDate, setReportDate] = useState(new Date());
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [roadDays, setRoadDays] = useState(daysOfRoad[1]);
  const [daysOfVacationChecked, setDaysOfVacationChecked] = useState(daysOfVacation[0]);
  const [returnDate, setReturnDate] = useState(new Date());
  const [unitInfo, setUnitInfo] = useState(null);
  const [form5Date, setForm5Date] = useState(new Date());
  const [form5Number, setForm5Number] = useState();

  useEffect(() => {
    const allowed = [];
    allReports.filter((item) => {
      if (item.allowedUnits.includes(loadedFile.unit)) {
        allowed.push({
          label: item.label,
          value: item.value
        });
      }
    });
    setAllowedReports(allowed);
  }, []);

  useEffect(() => {
    if (allowedReports.length) setReport(allowedReports[0]);
  }, [allowedReports]);

  useEffect(() => {
    const getCaptains = loadedFile.users
      .filter((item) => item.captain)
      .map((item) => {
        const nameArray = item.label.split(' ');
        return {
          label: `${nameArray[1]} ${nameArray[0]}`,
          value: `${nameArray[1]} ${nameArray[0]}`,
          rank: item.rank,
          position: item.firstCaptain
            ? `${item.position} ${loadedFile.unit}`
            : `ТВО ${loadedFile.users[0].position.toLowerCase()}а ${loadedFile.unit}`
        };
      });
    setCaptains(getCaptains);
    setCaptain(getCaptains[0]);
    setUnitInfo({
      unit: loadedFile.unit,
      unitFullName: loadedFile.unitFullName,
      number: loadedFile.number
    });
  }, []);

  const reportChange = (e) => {
    setReport(e);
  };

  const captainChange = (e) => {
    setCaptain(e);
  };

  const onCheckboxChange = (e) => {
    const user = loadedFile.users.findIndex((item) => item.id.toString() === e.target.name);
    // If first click
    if (checkedUsers.length === 0) setCheckedUsers([loadedFile.users[user]]);
    else {
      // Find is user already in array and delete if so
      const indexOfUserInChecked = checkedUsers.findIndex((item) => item.id.toString() === e.target.name);
      if (indexOfUserInChecked > -1) {
        const copyOfCheckedUsers = [...checkedUsers];
        copyOfCheckedUsers.splice(indexOfUserInChecked, 1);
        setCheckedUsers(copyOfCheckedUsers);
      }
      // Add user
      else setCheckedUsers([...checkedUsers, loadedFile.users[user]]);
    }
  };
  if (allowedReports.length > 0) {
    return (
      <div>
        <div className="settingsLine">
          <div className="select-container">
            <label>
              Тип рапорта
              <Select
                options={allowedReports}
                onChange={reportChange}
                value={report}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </label>
          </div>
          {report.value !== 'moneyReport' && report.value !== 'form5' && (
            <div className="select-container">
              <label>
                Командир
                <Select
                  options={captains || []}
                  onChange={captainChange}
                  value={captain || ''}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </label>
            </div>
          )}
          {report.value !== 'moneyReport' && report.value !== 'form5' && (
            <div className="select-container">
              <div>Дата рапорта</div>
              <DatePicker
                showIcon
                selected={reportDate}
                onChange={(date) => setReportDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          )}
        </div>
        {report.value === 'vacation' && (
          <div className="settingsLine">
            <div className="select-container">
              <label>
                К-ть днів відпустки
                <Select
                  options={daysOfVacation}
                  onChange={(e) => setDaysOfVacationChecked(e)}
                  value={daysOfVacationChecked}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </label>
            </div>
            <div className="select-container">
              <label>
                К-ть днів добирання
                <Select
                  options={daysOfRoad}
                  onChange={(e) => setRoadDays(e)}
                  value={roadDays}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </label>
            </div>
            <div className="select-container">
              <div>Дата відпустки</div>
              <DatePicker
                showIcon
                selected={vacationDate}
                onChange={(date) => setVacationDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        )}
        {report.value === 'return' && (
          <div className="settingsLine">
            <div className="select-container">
              <div>Дата повернення</div>
              <DatePicker
                showIcon
                selected={returnDate}
                onChange={(date) => setReturnDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        )}
        {report.value === 'moneyReport' ? (
          <Create10030Report />
        ) : report.value === 'form5' ? (
          <>
            <div className="settingsLine">
              <div className="select-container">
                <div>Дата форми 5</div>
                <DatePicker
                  showIcon
                  selected={form5Date}
                  onChange={(date) => setForm5Date(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div>
                <div>Перший номер форми 5</div>
                <input
                  className="form5-input"
                  type="number"
                  name="form5Name"
                  onChange={(e) => setForm5Number(Number(e.target.value))}
                />
              </div>
            </div>
            <Form5Report date={form5Date} number={form5Number} />
          </>
        ) : (
          <>
            <div className="checkBoxes">
              {loadedFile.users.map((item) => {
                if (!item.label) return null;
                return (
                  <div className="singleCheckBox" key={item.id}>
                    <label>
                      <input type="checkbox" name={item.id} onChange={onCheckboxChange} />
                      <span className="checkmark"></span>
                      {item.label}
                    </label>
                  </div>
                );
              })}
            </div>
            <CreateReport
              report={report}
              captain={captain}
              unitInfo={unitInfo}
              vacationDate={vacationDate}
              reportDate={reportDate}
              roadDays={roadDays}
              checkedUsers={checkedUsers}
              daysOfVacationChecked={daysOfVacationChecked}
              returnDate={returnDate}
            />
          </>
        )}
      </div>
    );
  } else {
    return <div>У вас немає дозволів</div>;
  }
};

export default Settings;

Settings.propTypes = {
  loadedFile: PropTypes.shape({
    unit: PropTypes.string,
    unitFullName: PropTypes.string,
    number: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        namewho: PropTypes.string,
        rank: PropTypes.string,
        rankWho: PropTypes.string,
        position: PropTypes.string,
        phoneNum: PropTypes.string,
        adress: PropTypes.string
      })
    )
  })
};

Settings.defaultProps = {
  loadedFile: null
};
