import React, { useState, useEffect, useRef } from 'react';
import { read, utils } from 'xlsx';
import Docxtemplater from 'docxtemplater';
import FileSaver from 'file-saver';
import PizZip from 'pizzip';
import f5Template from '../static/f5Template.docx';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/uk';

const Form5Report = ({ date, number }) => {
  const [soldiers, setSoldiers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generateButtonIsDissabled, setGenerateButtonIsDissabled] = useState(true);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!number || !date || loading) setGenerateButtonIsDissabled(true);
    else setGenerateButtonIsDissabled(false);
  }, [number, date, loading]);

  const handleLoadSoldierFileChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = read(binaryStr, { type: 'binary' });

      // Assuming the first sheet is the one you want
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert sheet to JSON
      const sheetData = utils
        .sheet_to_json(sheet, { header: 1 })
        .filter((row) => row.some((cell) => cell !== undefined && cell !== null && cell !== ''))
        .slice(1);
      const sheetDataLength = sheetData.length;
      const isOdd = sheetDataLength % 2 !== 0;

      // structure file to objects
      const structuredData = [];
      let twoSoldairs = {
        rank: '',
        name: '',
        type: '',
        f5Number: '',
        secondRank: '',
        secondName: '',
        secondType: '',
        secondF5Number: '',
        date: moment(date).format('DD MMMM YYYY')
      };
      const upercaseRank = (rank) => {
        return rank.charAt(0).toUpperCase() + rank.slice(1);
      };

      sheetData.forEach((item, index) => {
        if (isOdd && index === sheetDataLength - 1) {
          twoSoldairs.rank = upercaseRank(item[0]);
          twoSoldairs.name = item[1];
          twoSoldairs.type = item[2];
          twoSoldairs.f5Number = number + index;
          twoSoldairs.secondRank = upercaseRank(item[0]);
          twoSoldairs.secondName = item[1];
          twoSoldairs.secondType = item[2];
          twoSoldairs.secondF5Number = number + index;
          structuredData.push(twoSoldairs);
        } else {
          if ((index + 1) % 2 !== 0) {
            twoSoldairs.rank = upercaseRank(item[0]);
            twoSoldairs.name = item[1];
            twoSoldairs.type = item[2];
            twoSoldairs.f5Number = number + index;
          } else {
            twoSoldairs.secondRank = upercaseRank(item[0]);
            twoSoldairs.secondName = item[1];
            twoSoldairs.secondType = item[2];
            twoSoldairs.secondF5Number = number + index;

            structuredData.push(twoSoldairs);
            twoSoldairs = {
              rank: '',
              name: '',
              type: '',
              f5Number: '',
              secondRank: '',
              secondName: '',
              secondType: '',
              secondF5Number: '',
              date: moment(date).format('DD MMMM YYYY')
            };
          }
        }
      });

      setSoldiers(structuredData);
    };

    await reader.readAsArrayBuffer(file);
    setLoading(false);
  };

  const handleLoadPlaceButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDownloadFile = async () => {
    // Creating form 5 files
    const response = await fetch(f5Template);
    const templateArrayBuffer = await response.arrayBuffer();

    // Convert the ArrayBuffer to a Uint8Array
    const uint8Array = new Uint8Array(templateArrayBuffer);

    // Load the template content into a PizZip object
    const zip = new PizZip(uint8Array);

    // Initialize the docxtemplater with the PizZip object
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true, // Enable paragraph loop for repeating content
      linebreaks: true // Preserve line breaks in the output
    });
    // doc.loadZip(zip);

    // Replace the placeholders in the template with actual data
    doc.setData({ soldiers: soldiers });

    // Render the document (replace the placeholders with actual data)
    try {
      // Render the document (replace the placeholders with actual data)
      doc.render();
    } catch (error) {
      console.error('Error rendering the document:', error);
      return;
    }

    // Get the generated .docx document as a Blob
    const generatedDocx = doc.getZip().generate({ type: 'blob' });

    // Save the generated document using FileSaver.js
    FileSaver.saveAs(generatedDocx, `f5.docx`);
  };

  return (
    <>
      {!soldiers.length ? (
        <div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleLoadSoldierFileChange}
            accept=".xlsx"
          />
          <button className="button" disabled={generateButtonIsDissabled} onClick={handleLoadPlaceButtonClick}>
            Завантажте список
          </button>
        </div>
      ) : (
        <button className="button" onClick={handleDownloadFile}>
          Завантажити форму 5
        </button>
      )}
    </>
  );
};

export default Form5Report;

Form5Report.propTypes = {
  date: PropTypes.instanceOf(Date),
  number: PropTypes.number
};

Form5Report.defaultProps = {
  date: null,
  number: null
};
