import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Days from './components/Days';

function App() {
  return (
    <div className="mainContainer">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/days" element={<Days />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
